// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'


export const fridgeReportSlice = createSlice({
  name: 'fridgeReport',
  initialState: {
    events: [],
    selectedEvent: {},
    selectedCalendars: ['Personal', 'Business', 'Family', 'Holiday', 'ETC']
  },
  reducers: {
    selectEvent: (state, action) => {
      state.selectedEvent = action.payload
    },
    addOneEvent: (state, action) => {
      const newArr = [...state.events, action.payload]
      state.events = newArr
    },
    handleFridgeReport: (state, action) => {
      state.events = action.payload
    }
  }
})

export const { selectEvent, addOneEvent, handleFridgeReport } = fridgeReportSlice.actions

export default fridgeReportSlice.reducer
