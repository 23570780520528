// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import wizard from './wizard'
import calendar from "../views/dinner-solution/plan-meals-calendar/store"
import fridgeReport from "../views/dinner-solution/grocey-list/dinner-fridge-report/store"

const rootReducer = {
  auth,
  navbar,
  layout,
  calendar,
  fridgeReport,
  wizard
}

export default rootReducer
