// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'


export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    user: null,
    login: false,
    role: null,
    token: null,
  },
  reducers: {
    handleLogin: (state, action) => {
      state.user = action.payload.user
      state.role = action.payload.role
      state.login = action.payload.login
      state.token = action.payload.token
    },
    handleUser: (state, action) => {
      state.user = action.payload.user
    },
    handleLogout: state => {
      state.user = null
      state.role = null
      state.login = false
      state.token = null
      localStorage.setItem("token", "")
    }
  }
})

export const { handleLogin, handleLogout, handleUser } = authSlice.actions

export default authSlice.reducer
